// GENERATED CODE - DO NOT MODIFY BY HAND

export enum Tileset {
  BG0 = 0,
  BG1 = 1,
  BG2 = 2,
  BG3 = 3,
  BG4 = 4,
  BG5 = 5,
  BG6 = 6,
  BG7 = 7,
  Rock1 = 8,
  Rock2 = 9,
  Rock3 = 10,
  Rock4 = 11,
  Rock5 = 12,
  Rocks1 = 13,
  Rocks2 = 14,
  Rocks3 = 15,
  Moss1 = 16,
  Moss2 = 17,
  Moss3 = 18,
  Moss4 = 19,
  Moss5 = 20,
  Mosses1 = 21,
  Mosses2 = 22,
  Mosses3 = 23,
  Tree1 = 24,
  Tree2 = 25,
  Foliage1 = 26,
  Foliage2 = 27,
  Foliage3 = 28,
  Foliage4 = 29,
  Foliage5 = 30,
  Foliage6 = 31,
  Grass1 = 32,
  Grass2 = 33,
  Grass3 = 34,
  Water1 = 40,
  Water2 = 41,
  Water3 = 42,
  Water4 = 43,
  Floater1 = 44,
  Floater2 = 45,
  Floater3 = 46,
  Floater4 = 47,
  AirPath1 = 48,
  AirPath2 = 49,
  AirPath3 = 50,
  FirePath1 = 56,
  FirePath2 = 57,
  Debris1 = 58,
  Debris2 = 59,
  Debris3 = 60,
  Debris4 = 61,
}
export enum TileAnimationKey {
}
export const TileAnimations: { [key in TileAnimationKey]: number[] } = {
};
