/* Autogenerated file. Do not edit manually. */

import { TableId } from "@latticexyz/common";
import { defineComponent, Type as RecsType, World } from "@latticexyz/recs";

export function defineContractComponents(world: World) {
  return {
    Counter: (() => {
      const tableId = new TableId("", "Counter");
      return defineComponent(
        world,
        {
          value: RecsType.Number,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Realm: (() => {
      const tableId = new TableId("", "Realm");
      return defineComponent(
        world,
        {
          opener: RecsType.String,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Token: (() => {
      const tableId = new TableId("", "Token");
      return defineComponent(
        world,
        {
          coord: RecsType.BigInt,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Chamber: (() => {
      const tableId = new TableId("", "Chamber");
      return defineComponent(
        world,
        {
          opener: RecsType.String,
          tokenId: RecsType.Number,
          seed: RecsType.BigInt,
          yonder: RecsType.Number,
          chapter: RecsType.Number,
          terrain: RecsType.Number,
          entryDir: RecsType.Number,
          gemPos: RecsType.Number,
          gemType: RecsType.Number,
          coins: RecsType.Number,
          worth: RecsType.Number,
          agent: RecsType.String,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Agent: (() => {
      const tableId = new TableId("", "Agent");
      return defineComponent(
        world,
        {
          coord: RecsType.BigInt,
          seed: RecsType.BigInt,
          tokenId: RecsType.Number,
          yonder: RecsType.Number,
          terrain: RecsType.Number,
          gemType: RecsType.Number,
          coins: RecsType.Number,
          worth: RecsType.Number,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Tile: (() => {
      const tableId = new TableId("", "Tile");
      return defineComponent(
        world,
        {
          tokenId: RecsType.Number,
          terrain: RecsType.Number,
          tileType: RecsType.Number,
          isEntry: RecsType.Boolean,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Door: (() => {
      const tableId = new TableId("", "Door");
      return defineComponent(
        world,
        {
          nextCoord: RecsType.BigInt,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Position: (() => {
      const tableId = new TableId("", "Position");
      return defineComponent(
        world,
        {
          x: RecsType.Number,
          y: RecsType.Number,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Blocker: (() => {
      const tableId = new TableId("", "Blocker");
      return defineComponent(
        world,
        {
          enabled: RecsType.Boolean,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Player: (() => {
      const tableId = new TableId("", "Player");
      return defineComponent(
        world,
        {
          level: RecsType.Number,
          name: RecsType.String,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
    Location: (() => {
      const tableId = new TableId("", "Location");
      return defineComponent(
        world,
        {
          coord: RecsType.BigInt,
          agent: RecsType.String,
        },
        {
          metadata: {
            contractId: tableId.toHex(),
            tableId: tableId.toString(),
          },
        }
      );
    })(),
  };
}
